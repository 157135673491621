import React from 'react';

const Exhibitions = ({ isDesktop }) => {
  return (
    <div className={isDesktop ? 'settings' : 'settings-mobile'}>
      <p>
        <span style={{ fontWeight: 'bold' }}>2024</span> "Sensitivity Training"
        | HBK Braunschweig - Germany
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }}>2023</span> Phase | Group
        Exhibition with Class Antje Majewski Villa Minimo | Hannover - Germany
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }}>2023</span> "Interval No.14 Finale
        2" | HBK Braunschweig - Germany
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }}>2023</span> Spaces | Exhibition
        with Wael Ghanoum in Gausshaus | Braunschweig - Germany
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }}>2022</span> "Interval No.13 Finale"
        | HBK Braunschweig - Germany
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }}>2021</span> "Paralle Oceane" | Live
        Online | HBK Braunschweig - Germany
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }}>2018</span> Art participation in
        event | Haus der Kutur | Braunschweig - Germany
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }}>2016</span> Group exhibition | the
        Faculty of Arts university | Aleppo - Syria
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }}>2015</span> Material recycling -
        Collage | group exhibition by Issa Touma | Le Pont Gallery | Aleppo -
        Syria
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }}>2014</span> Post Cards in contact
        with artists from Netherlands | group exhibition by Issa Touma | Le Pont
        Gallery | Aleppo - Syria
      </p>
    </div>
  );
};

export default Exhibitions;
